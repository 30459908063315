import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Global, css } from "@emotion/core";

import App from "./App/App";
import { store } from "./store";

ReactDOM.render(
  <>
    <Global
      styles={css`
        @font-face {
          font-family: 'fira_monoregular';
          src: url("/fonts/firamono-regular.woff2") format("woff2"),
            url("/fonts/firamono-regular.woff") format("woff");
          font-weight: normal;
          font-style: normal;
        }

        html,
        body {
          width: 100%;
          height: 100%;
        }

        body {
          box-sizing: border-box;
          margin: 0;
          padding: 0;

          background-color: #2b2b2b;

          font-family: "fira_monoregular";

          position: relative;
        }

        #root {
          width: 100%;
          height: 100%;
        }
      `}
    />
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  document.getElementById("root")
);
