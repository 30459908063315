import {
  REQUEST_WEIGHT_PENDING,
  REQUEST_WEIGHT_SUCCESS,
} from "store/constants/constants-weights-info";

const initialStateWeight = {
  data: [],
  isPending: false,
};

export const requestWeights = (state = initialStateWeight, action = {}) => {
  switch (action.type) {
    case REQUEST_WEIGHT_PENDING:
      return { ...state, isPending: true };
    case REQUEST_WEIGHT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isPending: false,
      };
    default:
      return { ...state };
  }
};
