export const REQUEST_COMPANIES_INFO_PENDING = "REQUEST_COMPANIES_INFO_PENDING";
export const REQUEST_COMPANIES_INFO_SUCCESS = "REQUEST_COMPANIES_INFO_SUCCESS";

export const REQUEST_SELECTED_COMPANY_INFO_PENDING =
  "REQUEST_SELECTED_COMPANY_INFO_PENDING";
export const REQUEST_SELECTED_COMPANY_INFO_SUCCESS =
  "REQUEST_SELECTED_COMPANY_INFO_SUCCESS";
export const REQUEST_SELECTED_COMPANY_INFO_FAILED =
  "REQUEST_SELECTED_COMPANY_FAILED";

export const CHANGE_SELECTED_COMPANY_INFO = "CHANGE_SELECTED_COMPANY_INFO";

export const REQUEST_COMPANY_PROJECT_PENDING =
  "REQUEST_COMPANY_PROJECT_PENDING";
export const REQUEST_COMPANY_PROJECT_SUCCESS =
  "REQUEST_COMPANY_PROJECT_SUCCESS";
export const REQUEST_COMPANY_PROJECT_FAILED = "REQUEST_COMPANY_PROJECT_FAILED";
