import React from "react";
import { query } from "App/theme/custom-media";
import { createContext, memo, useEffect, useState } from "react";

function initializeViewportContextValue() {
  const mediaMatches = Object.keys(query).reduce(
    (mediaMatches, breakpoint) =>
      Object.assign(mediaMatches, { [breakpoint]: true }),
    {}
  );

  return {
    ...mediaMatches,
    isTouchDevice: false,
  };
}

const ViewportContext = createContext(initializeViewportContextValue());

/**
 * Determines the current viewport capabilities, like its dimensions and
 * touch support.
 * For now it **only works on client-side**.
 */
const ViewportProvider = memo(function ViewportProvider(props) {
  const [contextValue, setContextValue] = useState(
    initializeViewportContextValue
  );

  useEffect(() => {
    const cleanup = [];
    const initialContextValue = {};

    Object.entries(query).forEach(([key, mediaQuery]) => {
      const breakpoint = key;

      const mediaMatcher = window.matchMedia(mediaQuery);
      const listener = (e) =>
        setContextValue((value) => ({ ...value, [breakpoint]: e.matches }));
      mediaMatcher.addListener(listener);
      cleanup.push(() => mediaMatcher.removeListener(listener));

      initialContextValue[breakpoint] = mediaMatcher.matches;
    });

    initialContextValue.isTouchDevice =
      "ontouchstart" in window ||
      window.navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0;

    setContextValue((value) => ({ ...value, ...initialContextValue }));

    return () => cleanup.forEach((c) => c());
  }, []);

  return <ViewportContext.Provider value={contextValue} {...props} />;
});

export { ViewportContext, ViewportProvider };
