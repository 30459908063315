import React from "react";
import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";

const loadingElement = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingDiv = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

const FirstDiv = styled.div`
  animation: ${loadingElement} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.036s;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;

    top: 63px;
    left: 63px;
  }
`;

const SecondDiv = styled.div`
  animation: ${loadingElement} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.072s;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;

    top: 68px;
    left: 56px;
  }
`;

const ThirdDiv = styled.div`
  animation: ${loadingElement} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.108s;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;

    top: 71px;
    left: 48px;
  }
`;

const ForthDiv = styled.div`
  animation: ${loadingElement} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.144s;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;

    top: 72px;
    left: 40px;
  }
`;

const FifthDiv = styled.div`
  animation: ${loadingElement} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.18s;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;

    top: 71px;
    left: 32px;
  }
`;

const SixthDiv = styled.div`
  animation: ${loadingElement} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.216s;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;

    top: 68px;
    left: 24px;
  }
`;

const SeventhDiv = styled.div`
  animation: ${loadingElement} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.252s;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;

    top: 63px;
    left: 17px;
  }
`;

const EighthDiv = styled.div`
  animation: ${loadingElement} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  animation-delay: -0.288s;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;

    top: 56px;
    left: 12px;
  }
`;

const LoadingElement = () => {
  return (
    <LoadingDiv>
      <FirstDiv />
      <SecondDiv />
      <ThirdDiv />
      <ForthDiv />
      <FifthDiv />
      <SixthDiv />
      <SeventhDiv />
      <EighthDiv />
    </LoadingDiv>
  );
};

export { LoadingElement };
