import {
  REQUEST_QUOTE_PENDING,
  REQUEST_QUOTE_SUCCESS,
  REQUEST_QUOTE_FAILED,
} from "store/constants/constants-quote";

const initialStateQuote = {
  quote: "",
  author: "",
  error: "",
  isPending: false,
};

export const requestQuote = (state = initialStateQuote, action = {}) => {
  switch (action.type) {
    case REQUEST_QUOTE_PENDING:
      return { ...state, isPending: true };
    case REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload.quote,
        author: action.payload.author,
      };
    case REQUEST_QUOTE_FAILED:
      return { ...state, error: action.payload, isPending: false };
    default:
      return { ...state };
  }
};
