import React from "react";
import styled from "@emotion/styled";

import { PRELOAD_IMAGE_LIST } from "variables/constants";

const Div = styled.div`
  width: 1px;
  height: 1px;

  position: fixed;

  visibility: hidden;
`;

const getImageList = () => {
  let response = [];
  const keys = Object.keys(PRELOAD_IMAGE_LIST);

  keys.forEach((key, keyIndex) => {
    const { path, list } = PRELOAD_IMAGE_LIST[key];

    list.forEach((image, imageIndex) => {
      const id = `${keyIndex}-${imageIndex}`;

      response.push(
        <img key={id} src={`${path}${image}`} loading="lazy" alt={image} />
      );
    });
  });

  return response;
};

const PreloadImages = React.memo(() => {
  const imageList = getImageList();

  return <Div>{imageList}</Div>;
});

export default PreloadImages;
