import { createStore, applyMiddleware, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";

import {
  requestUserName,
  requestContactProperties,
  requestUserInfo,
} from "./reducers/reducers-user-info";
import {
  requestCompanies,
  requestSelectedCompany,
  requestCompanyProject,
} from "./reducers/reducers-companies-info";
import { requestQuote } from "./reducers/reducers-quote";
import { requestWeights } from "./reducers/reducers-weights-info";

const logger = createLogger();
const rootReducer = combineReducers({
  requestUserName,
  requestContactProperties,
  requestUserInfo,
  requestCompanies,
  requestSelectedCompany,
  requestCompanyProject,
  requestQuote,
  requestWeights,
});

const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware, logger)
);

export { store };
