export const defaultOptions = [
  { id: 1, name: "About", value: "about", icon: "about" },
  { id: 2, name: "Experience", value: "experience", icon: "experience" },
  { id: 3, name: "Contacts", value: "contact", icon: "contact" },
];

export const allOptions = [
  { id: 0, name: "Homepage", value: "", icon: "home" },
  ...defaultOptions,
];

export const IMAGES_ASSET_URL = "/assets/images/";
export const LOGOS_ASSET_URL = "/assets/logos/";
export const ICONS_ASSET_URL = "/assets/icons/";
export const PRELOAD_IMAGE_LIST = {
  images: {
    path: IMAGES_ASSET_URL,
    list: ["eduardo.jpeg"],
  },
  logos: {
    path: LOGOS_ASSET_URL,
    list: [
      "blip.png",
      "esnporto.png",
      "hackthon.png",
      "majo.png",
      "thisplace.svg",
      "webmadeira.png",
      "github_black.svg",
      "github_grey.svg",
      "linkedin.svg",
      "linkedin_colour.svg",
      "mindera.png",
      "gmail",
      "tech_angular.svg",
      "tech_css3.svg",
      "tech_html5.svg",
      "tech_jquery.svg",
      "tech_js.svg",
      "tech_less.svg",
      "tech_node.svg",
      "tech_php.svg",
      "tech_react.svg",
      "tech_redux.svg",
      "tech_sass.svg",
      "tech_sql.svg",
      "tech_ts.svg",
    ],
  },
  icons: {
    path: ICONS_ASSET_URL,
    list: [
      "icon_about.png",
      "icon_contact.png",
      "icon_experience.png",
      "icon_home.png",
    ],
  },
};
