const query = {
  small: "(max-width: 1023px)", // <= 1023px
  medium: "(min-width: 1024px) and (max-width: 1365px)", // >= 1024px && <= 1365px
  large: "(min-width: 1366px) and (max-width: 1919px)", // >= 1366px && <= 1919px
  extraLarge: "(min-width: 1920px)", // >= 1920px

  gteMedium: "(min-width: 1024px)", // >= 1024px
  gteLarge: "(min-width: 1366px)", // >= 1366px

  lteMedium: "(max-width: 1365px)", // <= 1365px
  lteLarge: "(max-width: 1919px)", // <= 1919px
};

const media = {
  small: `@media ${query.small}`, // <= 1023px
  medium: `@media ${query.medium}`, // >= 1024px && <= 1365px
  large: `@media ${query.large}`, // >= 1366px && <= 1919px
  extraLarge: `@media ${query.extraLarge}`, // >= 1920px

  gteMedium: `@media ${query.gteMedium}`, // >= 1024px
  gteLarge: `@media ${query.gteLarge}`, // >= 1366px

  lteMedium: `@media ${query.lteMedium}`, // <= 1365px
  lteLarge: `@media ${query.lteLarge}`, // <= 1919px
};

export { query, media };
