import React, { Suspense, lazy, useMemo } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import styled from "@emotion/styled";

import PreloadImages from "App/components/PreloadImages";
import { LoadingElement } from "App/components/LoadingElement";
import { ViewportProvider } from "App/components/ViewportContext";
import { ModalProvider } from "App/components/ModalProvider";

const AppSection = styled.section`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppDiv = styled.div`
  width: 100%;
  height: 100%;

  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const About = lazy(() => import("App/scenes/About/About"));
const Contact = lazy(() => import("App/scenes/Contact/Contact"));
const Home = lazy(() => import("App/scenes/Home/Home"));
const Projects = lazy(() => import("App/scenes/Projects/Projects"));
const Weight = lazy(() => import("App/scenes/Weight-stats/Weight.js"));

function App() {
  const { search } = window.location;
  let showWeight = useMemo(() => {
    const params = search.substring(1).split("&");

    for (let param of params) {
      if (param.includes("segment")) {
        // eslint-disable-next-line no-unused-vars
        const [_, value] = param.split("=");

        if (value === "B") {
          return true;
        }
      }
    }

    return false;
  }, [search]);

  return (
    <AppSection>
      <AppDiv>
        <Suspense fallback={<LoadingElement />}>
          <ViewportProvider>
            <BrowserRouter>
              <ModalProvider>
                <Route path="/about">
                  <About animate={true} />
                </Route>
                <Route path="/experience">
                  <Projects animate={true} />
                </Route>
                <Route path="/contact">
                  <Contact animate={true} />
                </Route>
                {showWeight && (
                  <Route path="/weight-stats">
                    <Weight animate={true} />
                  </Route>
                )}
                <Route path="/" exact>
                  <Home animate={true} />
                </Route>
              </ModalProvider>
            </BrowserRouter>
          </ViewportProvider>
        </Suspense>
        <PreloadImages />
      </AppDiv>
    </AppSection>
  );
}

export default App;
