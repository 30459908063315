import {
  REQUEST_USERNAME_PENDING,
  REQUEST_USERNAME_SUCCESS,
  REQUEST_INFO_PENDING,
  REQUEST_INFO_SUCCESS,
  REQUEST_PROPERTIES_PENDING,
  REQUEST_PROPERTIES_SUCCESS,
} from "store/constants/constants-user-info";

const initialStateUserInfo = {
  userName: "",
  isPending: false,
  error: "",
};
const initialStateContactInfo = {
  userName: "",
  email: "",
  linkedin: "",
  github: "",
  role: "",
  isPending: false,
  error: "",
};
const initialStateAboutInfo = {
  userName: "",
  userTitle: "",
  userAbout: [],
  userDescription: "",
  userLanguages: [],
  userMilestones: [],
  userCity: {
    title: "",
    latitude: 0,
    longitude: 0,
    color: "",
  },
  animate: false,
  isPending: false,
  error: "",
};

export const requestUserName = (state = initialStateUserInfo, action = {}) => {
  switch (action.type) {
    case REQUEST_USERNAME_PENDING:
      return { ...state, isPending: true };
    case REQUEST_USERNAME_SUCCESS:
      return { ...state, userName: action.payload.name, isPending: false };
    default:
      return { ...state };
  }
};
export const requestContactProperties = (
  state = initialStateContactInfo,
  action = {}
) => {
  switch (action.type) {
    case REQUEST_PROPERTIES_PENDING:
      return { ...state, isPending: true };
    case REQUEST_PROPERTIES_SUCCESS:
      return {
        ...state,
        userName: action.payload.name,
        email: action.payload.email,
        linkedin: action.payload.linkedin,
        github: action.payload.github,
        role: action.payload.title,
        isPending: false,
      };
    default:
      return { ...state };
  }
};
export const requestUserInfo = (state = initialStateAboutInfo, action = {}) => {
  switch (action.type) {
    case REQUEST_INFO_PENDING:
      return { ...state, isPending: true };
    case REQUEST_INFO_SUCCESS:
      return {
        ...state,
        userName: action.payload.name,
        userTitle: action.payload.title,
        userAbout: action.payload.about,
        userDescription: action.payload.description,
        userLanguages: action.payload.languages,
        userMilestones: action.payload.milestones,
        userCity: action.payload.city,
        animate: true,
        isPending: false,
      };
    default:
      return { ...state };
  }
};
