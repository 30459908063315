import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

const WrapperDiv = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;

  position: fixed;

  background-color: rgba(0, 0, 0, 0.3);

  ${({ removeOverlay }) =>
    removeOverlay &&
    css`
      background-color: transparent;
      pointer-events: none;
    `}
`;

const ModalWrapperDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Modal = (props) => {
  const { open, removeOverlay, children } = props;
  return open ? (
    <WrapperDiv removeOverlay={removeOverlay}>
      <ModalWrapperDiv>{children}</ModalWrapperDiv>
    </WrapperDiv>
  ) : null;
};

export { Modal };
