import {
  REQUEST_COMPANIES_INFO_PENDING,
  REQUEST_COMPANIES_INFO_SUCCESS,
  REQUEST_SELECTED_COMPANY_INFO_PENDING,
  REQUEST_SELECTED_COMPANY_INFO_SUCCESS,
  REQUEST_SELECTED_COMPANY_INFO_FAILED,
  CHANGE_SELECTED_COMPANY_INFO,
  REQUEST_COMPANY_PROJECT_PENDING,
  REQUEST_COMPANY_PROJECT_SUCCESS,
  REQUEST_COMPANY_PROJECT_FAILED
} from "store/constants/constants-companies-info";

const initialStateCompanies = {
  userCompanies: [],
  animate: false,
  isPending: false,
  error: ""
};

const initialStateSelectedCompany = {
  selectedCompany: {},
  animate: false,
  isPending: false,
  error: ""
};

const initialStateSelectedCompanyProject = {
  companyProjects: [],
  isPending: false,
  error: ""
};

export const requestCompanies = (
  state = initialStateCompanies,
  action = {}
) => {
  switch (action.type) {
    case REQUEST_COMPANIES_INFO_PENDING:
      return { ...state, isPending: true };
    case REQUEST_COMPANIES_INFO_SUCCESS:
      return { ...state, userCompanies: action.payload, isPending: false };
    default:
      return { ...state };
  }
};

export const requestSelectedCompany = (
  state = initialStateSelectedCompany,
  action = {}
) => {
  switch (action.type) {
    case REQUEST_SELECTED_COMPANY_INFO_PENDING:
      return { ...state, isPending: true };
    case REQUEST_SELECTED_COMPANY_INFO_SUCCESS:
      return { ...state, selectedCompany: action.payload, isPending: false };
    case REQUEST_SELECTED_COMPANY_INFO_FAILED:
      return { ...state, error: action.payload, isPending: false };
    case CHANGE_SELECTED_COMPANY_INFO:
      return { ...state, selectedCompany: action.payload };
    default:
      return { ...state };
  }
};

export const requestCompanyProject = (
  state = initialStateSelectedCompanyProject,
  action = {}
) => {
  switch (action.type) {
    case REQUEST_COMPANY_PROJECT_PENDING:
      return { ...state, isPending: true };
    case REQUEST_COMPANY_PROJECT_SUCCESS:
      return { ...state, companyProjects: action.payload, isPending: false };
    case REQUEST_COMPANY_PROJECT_FAILED:
      return { ...state, error: action.payload, isPending: false };
    default:
      return { ...state };
  }
};
