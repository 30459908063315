import React, { createContext, useReducer } from "react";

import { Modal } from "App/components/Modal";

const ModalContext = createContext(null);

const initialState = { isOpen: false, modalContent: null, modalOptions: {} };

const ModalProvider = ({ children }) => {
  const [{ isOpen, modalOptions, modalContent }, dispatch] = useReducer(
    (_, action) => {
      switch (action.type) {
        case "showModal":
          return {
            isOpen: true,
            modalContent: action.payload.modalContent,
            modalOptions: action.payload.modalOptions,
          };
        case "hideModal":
        default:
          return initialState;
      }
    },
    initialState
  );

  const closeModal = () => {
    dispatch({
      type: "hideModal",
    });
  };

  const showModal = (content, options) => {
    dispatch({
      type: "showModal",
      payload: { modalContent: content, modalOptions: options },
    });
  };

  return (
    <ModalContext.Provider value={{ closeModal, showModal, isOpen }}>
      {children}
      <Modal open={isOpen} {...modalOptions}>
        {modalContent}
      </Modal>
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };
