import { useContext } from "react";

import { ModalContext } from "./ModalProvider";

function useModalProvider() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModalProvider needs to be used inside ModalProvider");
  }

  return context;
}

export { useModalProvider };
